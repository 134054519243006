<template>
  <form @submit.prevent="handleSubmit" method="POST" class="w-5/6 mx-auto">
    <div>
      <div class="px-2">
        <div class="grid grid-cols-12 gap-6">
          <div class="col-span-6">
              <label for="name" class="block text-sm font-medium text-gray-700">Titre (Requis)</label>
              <input type="text" name="name" id="name" autocomplete="name" v-model="article.title" class="mt-1 focus:ring-primary-500 focus:border-primary-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md" />
          </div>
            <div class="col-span-6">
              <label for="name" class="block text-sm font-medium text-gray-700">Auteur (Requis)</label>
              <input type="text" name="name" id="name" autocomplete="name" v-model="article.author" class="mt-1 focus:ring-primary-500 focus:border-primary-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md" />
          </div>
          <div class="col-span-6">
            <label for="description" class="block text-sm font-medium text-gray-700">Description (Requis)</label>
            <div class="mt-1 sm:mt-0 sm:col-span-2">
              <textarea id="description" name="description" rows="3" v-model="article.summary" class="mt-1 shadow-sm block w-full h-24 focus:ring-primary-500 focus:border-primary-500 sm:text-sm border border-gray-300 rounded-md" />
              <p class="mt-2 text-sm text-gray-500">
                Écrivez quelques phrases sur {{ isAdvocacy ? "le plaidoyer.":"l'actualité." }} 
              </p>
            </div>          
          </div>
          <div class="col-span-6">
            <label for="avatar" class="block text-sm font-medium text-gray-700"
              >Thumbnail (Requis)</label
            >
            <div class="flex items-center mt-1">
              <span class="h-10 w-10 rounded-full overflow-hidden bg-gray-100">
                <svg
                  v-show="!selectedImage"
                  class="h-full w-full text-gray-300"
                  fill="currentColor"
                  viewBox="0 0 24 24"
                >
                  <path
                    d="M24 20.993V24H0v-2.996A14.977 14.977 0 0112.004 15c4.904 0 9.26 2.354 11.996 5.993zM16.002 8.999a4 4 0 11-8 0 4 4 0 018 0z"
                  />
                </svg>
                <img v-show="selectedImage" :src="selectedImage" alt="" />
              </span>
              <label
                for="community-avatar"
                class="
                  ml-5
                  bg-white
                  py-2
                  px-3
                  border border-gray-300
                  rounded-md
                  shadow-sm
                  text-sm
                  leading-4
                  font-medium
                  text-gray-700
                  hover:bg-gray-50
                  focus:outline-none
                  focus:ring-2
                  focus:ring-offset-2
                  focus:ring-primary
                "
                >Modifier</label
              >
              <input
                @change="updateSelectedFile"
                type="file"
                hidden
                id="community-avatar"
              />
            </div>
          </div>
        </div>
        <div class="w-full mt-6">
          <label for="description" class="block text-sm font-medium text-gray-700 mb-1">Contenu (Requis)</label>
          <QuillEditor id="quill-editor" :toolbar="toolbar" theme="snow" v-model:content="article.content" 
          />  
        </div>
      </div>
    </div>
    <div class="py-4 text-right px-2">
      <button type="submit" class="inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-primary hover:bg-primary-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500">Enregistrer</button>
    </div>
  </form>
</template>

<script setup>
import { ref } from "vue";
import {useAuth} from "@/composables/auth";
import {useStore} from "vuex";
const {currentUser} = useAuth();
const {dispatch} = useStore();
const emit = defineEmits(['modalClose']);
const props = defineProps({
  article:{
    type:Object,
    default:{}
  },
  isAdvocacy:{
    type:Boolean,
    default:false
  }
});
const article = ref(props.article);
const selectedImage = ref(null);
const selectedFile = ref(null);
const updateSelectedFile = (e) => {
  selectedFile.value = e.target.files;
  selectedImage.value = URL.createObjectURL(selectedFile.value[0]);
};
if(article.value.id && article.value.content.length){
  article.value.content = JSON.parse(article.value.content)
}
const toolbar = [
  [{'header': [1, 2, 3, 4, 5, 6, false]}],
  ['bold','italic','underline','strike'],
  [{'align': []}],
  [{'list':'ordered'}, {'list':'bullet'}],
  ['link','image']
];
const handleSubmit = async () => {
  // Stringify article content
  article.value.content = JSON.stringify(article.value.content); 
  // Upload File 
  if (selectedFile.value?.length) {
    const [{id}] = await dispatch("files/upload", selectedFile.value);
    article.value.backgroundId = id;
  }
  // Update article
  if(article.value.id){
    try {
      const updatedArticle = await dispatch('news/update', {
        article:article.value,
        isAdvocacy:props.isAdvocacy
      });
      emit("modalClose"); 
    } catch (err) {
      const {message} = err.response.data;
      console.log(message);
    }
  }
  // Create article
  else{
    try {
      if(props.isAdvocacy){
        article.value.isAdvocacy = true;
      }
      const newArticle = await dispatch('news/create', {
        article:article.value,
        isAdvocacy:props.isAdvocacy
      });
      emit("modalClose"); 
    } catch (err) {
      const {message} = err?.response?.data;
      console.log(message);
    }
  }
}
</script>

<style lang="scss">
  .ql-editor{
    @apply min-h-[250px];
  }
</style>